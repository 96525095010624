import axios from "axios";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import "../css/Home.css";
import "../css/HomeTestimoni.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HomeHeader from "../Header/HomeHeader";
import { HomeQnaContainer } from "../HomeQnaContainer/HomeQnaContainer";
import Narasumber from "../Narasumber/Narasumber";
import VideoSection from "../NewComponents/VideoSection";
import NewEpisode from "../NewEpisode/NewEpisode";
import Playlist from "../Playlist/Playlist";
import PopupEndQna from "../Popup/PopupEndQna";
import PopupNotLogin from "../Popup/PopupNotLogin";
import PopupOver from "../Popup/PopupOver/PopupOver";
import QnaSection from "../QnaSection/QnaSection";
import PopupTestimoni from "../Subscription/Popup-Testimoni";
import TestimoniSection from "../Subscription/Testimoni-Section";
import TopEpisode from "../TopEpisode/TopEpisode";
import MetaTagContent from "./MetaTagContent";

function Home() {
  const [isOpenPopup, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [dialogId, setDialogId] = useState(null);
  const [data, setData] = useState({});
  const [originalseries, setOriginalSeries] = useState([]);
  const [isOpenSubscription, setIsOpenSubscription] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openFirstPopup, setOpenFirstPopup] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [memberType, setMemberType] = useState(false);
  const [quotaQna, setQuotaQna] = useState(0);
  const [askQuestion, setAskQuestion] = useState(false);
  const [profileRules, setProfileRules] = useState({});
  const [qna, setQna] = React.useState({});
  const [qnaFormStatus, setQnaFormStatus] = React.useState([]);

  useEffect(() => {
    const qnas = localStorage.getItem("quotaQna");
    if (qnas !== null) {
      setQuotaQna(qnas);
    }
  }, []);

  const isAlreadyOpen = sessionStorage.getItem("open");
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("isLangganan")) {
      setIsOpen(true);
      document.style.overflow = "hidden";
    }

    if (isOpenPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenPopup]);
  useEffect(() => {
    if (openLogin) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openLogin]);

  useEffect(() => {
    if (openFirstPopup) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "unset";
  }, [openFirstPopup]);

  useEffect(() => {
    async function getAPI() {
      const res = await axios.get(
        process.env.REACT_APP_FETCH_URL + "/home-audio"
      );
      const resOriginal = await axios.get(
        process.env.REACT_APP_FETCH_URL + "/home"
      );
      const originalData = resOriginal?.data?.filter(
        (item) => item?.data_type === "kelas_original_series"
      );
      if (res && originalData) {
        setData(res.data);
        setOriginalSeries(originalData);
        setLoaded(false);
      } else {
        setLoaded(false);
      }

      const token = localStorage.getItem("token");
      if (token === null) {
        setTimeout(() => {
          setOpenFirstPopup(isAlreadyOpen === null);
          sessionStorage.setItem("open", "true");
        }, 200);
      }
    }
    getAPI();
  }, []);

  const gotoTypeForm = React.useCallback(() => {
    if (localStorage.getItem("token") === null) {
      setOpenLogin(true);
      document.body.style.overflow = "hidden";
      return;
    }

    setAskQuestion(true);
    return;
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      setIsLogin(true);
      return;
    }
  }, []);

  return (
    <div id="home-container">
      <MetaTagContent
        url={"https://fammi.ly/"}
        image="https://fammi.ly/images/home-1.png"
      />
      {loaded && (
        <div className="wrap-load" style={{ zIndex: "99" }}>
          <div className="content-load">
            <div className="loader">&nbsp;</div>
            <p>Harap Tunggu</p>
          </div>
        </div>
      )}
      <Header setQuotaQna={setQuotaQna} setMemberType={setMemberType} />
      <div id="home-headers" style={{ maxWidth: "1366px", margin: "0 auto" }}>
        <HomeHeader click={gotoTypeForm} />
      </div>
      <div
        id={"typeforms"}
        style={{ position: "absolute", top: "0px", visibilty: "hidden" }}
      ></div>
      {/*<QnaSection*/}
      {/*  data={data["qna"]}*/}
      {/*  isLogin={isLogin}*/}
      {/*  setOpenLogin={setOpenLogin}*/}
      {/*  setQuotaQna={setQuotaQna}*/}
      {/*  quotaQna={quotaQna}*/}
      {/*  setOpenSubs={setIsOpenSubscription}*/}
      {/*/>*/}
      {(!isLogin || memberType !== "member") && (
        <div
          id="additional-banner-section"
          style={{
           cursor: "pointer", display: "none"
          }}
          onClick={() => {
            history.push("/daftar-member");
          }}
        >
          <div id="additional-banner-section-desktop">
            <img
              src="assets/images/additional_banner_desktop.png"
              alt="banner-desktop"
            />
          </div>
          <div id="additional-banner-section-mobile">
            <img
              src="assets/images/additional_banner_mobile.png"
              style={{ width: "100%" }}
              alt="banner-mobile"
            />
          </div>
        </div>
      )}
      <NewEpisode
        data={data["newEpisode"]}
        isLogin={isLogin}
        setOpenLogin={setOpenLogin}
      />
      <TopEpisode
        data={data["topEpisode"]}
        isLogin={isLogin}
        setOpenLogin={setOpenLogin}
      />
      <Playlist
        data={data["homePlaylist"]}
        isLogin={isLogin}
        setOpenLogin={setOpenLogin}
      />
      <Narasumber
        data={data["speaker"]}
        isLogin={isLogin}
        setOpenLogin={setOpenLogin}
      />
      <div className="mob-division" />
      <div id="parent-video-container">
        <VideoSection />
      </div>
      {dialogId && (
        <PopupTestimoni
          clicks={() => {
            setDialogId(null);
            document.body.style.overflow = "unset";
          }}
          id={dialogId}
        />
      )}
      <TestimoniSection
        clicks={(id) => {
          setDialogId(id);
          document.body.style.overflow = "hidden";
        }}
      />
      {/* <BannerToSubscription /> */}
      <Footer />
      {openFirstPopup && sessionStorage.getItem("open") === null && (
        <PopupNotLogin
          onClose={() => {
            setOpenFirstPopup(false);
            sessionStorage.setItem("open", "false");
          }}
        />
      )}
      {openLogin && (
        <PopupNotLogin
          onClose={() => {
            setOpenLogin(false);
            sessionStorage.setItem("open", "false");
          }}
        />
      )}
      {isOpenPopup && (
        <PopupOver
          berlangganan
          click={(e) => {
            if (e.target !== document.getElementById("box-over")) {
              setIsOpen(false);
              localStorage.removeItem("isLangganan");
            }
          }}
        />
      )}
      {isOpenSubscription && (
        <PopupEndQna
          push={() => {
            if (!isLogin) {
              history.push("/daftar-member");
              return;
            }
            history.push("/masuk-member");
          }}
          onLangganans={() => {
            if (!isLogin) {
              history.push("/daftar-member");
              return;
            }
            history.push("/masuk-member");
          }}
          onClose={() => {
            setIsOpenSubscription(false);
            document.body.style.overflow = "auto";
          }}
        />
      )}
      {askQuestion && (
        <HomeQnaContainer
          onClose={() => setAskQuestion(false)}
          profileRules={{
            no_whatsapp: "visible"
          }}
          qnaFormStatus={qnaFormStatus}
          questionData={{
            tags:"home"
          }}
        />
      )}
    </div>
  );
}
export default withRouter(Home);
