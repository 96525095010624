import React, { Component } from "react";
import Images from "../ImagesComponent/Images";
import "../css/Footer.css";

class Footer extends Component {
  render() {
    return (
      <div id="footer-container">
        <div id="footer" className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-3 companydetail">
                <div className="col-12">
                  <Images
                    className="footerimagelogo"
                    src="images/fammi-logo.svg"
                    style={{
                      width: "108px",
                      height: "32px",
                      marginLeft: "-6px",
                    }}
                  />
                  <p>
                    PT. Fammi Indonesia Edutech
                    <br />
                    Jl. Ir. H. Djuanda No. 299, Kota Bandung, Indonesia
                    {/*Jl. Naripan No. 53, Bandung City, West Java 40112, Indonesia*/}
                    <br />
                    <br />
                    Copyright © 2022 Fammi Indonesia
                  </p>
                </div>
              </div>
              <div className="col-md-9 col-sm-9 footerlink">
                <ul className="col-md-3 col-sm-4 col-xs-6">
                  <h4>Perusahaan</h4>
                  <li>
                    <a href="/tentang">Tentang Kami</a>
                  </li>
                  <li>
                    <a href="/ketentuan">Syarat & Ketentuan</a>
                  </li>
                  <li>
                    <a href="/kebijakan">Kebijakan Data</a>
                  </li>
                </ul>
                <ul id="team-work-fot" className="col-md-3 col-sm-4 col-xs-6">
                  <h4>Kerjasama</h4>
                  <li>
                    <a href="/join">Narasumber</a>
                  </li>
                  <li>
                    <a href="/join">Mitra</a>
                  </li>
                </ul>
                <ul id="product-fot" className="col-md-3 col-sm-4 col-xs-6">
                  <h4>Produk</h4>
                  <li>
                    <a href="/kelas">Kelas</a>
                  </li>
                  <li>
                    <a href="/kelas?filter-kelas=akademi">Akademi</a>
                  </li>
                </ul>
                <ul className="col-md-3  col-sm-4 col-xs-6 footersocial">
                  <h4>Ikuti Kami di</h4>
                  <li>
                    <a
                      href="https://www.instagram.com/fammiindonesia/"
                      target="_blank"
                      className="instagram"
                    >
                      @fammiindonesia
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/fammi.indonesia"
                      target="_blank"
                      className="facebook"
                    >
                      @fammiindonesia
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/fammiindonesia"
                      target="_blank"
                      className="telegram"
                    >
                      t.me/fammiindonesia
                    </a>
                  </li>
                </ul>
                <ul
                  id="hubungi-fot"
                  className="col-md-3 col-sm-4 col-xs-6 footerhubungi"
                >
                  <h4 style={{ marginBottom: "24px", marginTop: "26px" }}>
                    Hubungi Kami
                  </h4>
                  <li>
                    <a href="#">
                      <Images
                        src="images/email.svg"
                        style={{
                          width: "22px",
                          float: "left",
                          margin: "8px 10px 0 0",
                        }}
                      />
                      Email
                      <br />
                      care@fammi.ly
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/6282126600093">
                      <Images
                        src="images/wa.svg"
                        style={{
                          width: "22px",
                          float: "left",
                          margin: "5px 10px 0 0",
                        }}
                      />
                      WhatsApp
                      <br />
                      0821-2660-0093
                    </a>
                  </li>
                </ul>
                <ul
                  id="download-fot"
                  className="col-md-3 col-sm-4 col-xs-6 download"
                >
                  <h4 style={{ marginBottom: "24px", marginTop: "26px" }}>
                    Download Fammi
                  </h4>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=ly.fammi.kota"
                      target="_blank"
                    >
                      <Images
                        src="images/google-play.png"
                        style={{ width: "144px" }}
                      />
                    </a>
                  </li>
                  <li style={{display: "none"}}>
                    <a
                      href="https://apps.apple.com/id/app/fammi/id1491110705"
                      target="_blank"
                    >
                      <Images
                        src="images/app-store.png"
                        style={{ width: "144px" }}
                      />
                    </a>
                  </li>
                  {/* <li><a href="#"><Images src="images/website.png"  style={{width:'144px'}}/></a></li>  */}
                </ul>
              </div>
            </div>
          </div>
          <div className="container signsub footer" style={{ display: "none" }}>
            <div className="footerrow">
              <div className="footercol">
                <div className="footersignup">
                  <p>
                    Banyak keluarga di Indonesia menunggu Anda. Mari bersama
                    memberikan kontribusi terbaik. Daftar menjadi Mitra FAMMI
                    sekarang !
                  </p>
                  <div className="buttoncontainer">
                    <a href="/join" className="fammibutton2">
                      Selengkapnya
                    </a>
                  </div>
                </div>
              </div>
              <div className="seperator"> </div>
              <div className="footercol">
                <div className="footersignup">
                  <p>
                    Akses berbagai macam Kelas dan Akademi untuk meningkatkan
                    kualitas sebagai orangtua masa kini. Gabung segera menjadi
                    orangtua pembelajar.
                  </p>
                  <div className="buttoncontainer">
                    <a href="/register" className="fammibutton2">
                      Daftar
                    </a>
                    <a href="/login" className="fammibutton3">
                      Masuk
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row langnwa">
              <div id="menu-languange" className="col-md-6 col-xs-6 flg">
                <div className="row wanlang">
                  <div
                    className="col-md-6 col-sm-6 col-xs-12"
                    style={{ display: "none" }}
                  >
                    <select name="cars" id="language">
                      <option value="Indonesia">ID</option>
                      <option value="English">EN</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6 fwa col-xs-6">
                <div className="row wanlang footer">
                  <a
                    href="http://wa.me/6282126660449"
                    className="fammibuttonalt colorbg3 col-12"
                  >
                    WhatsApp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
