import React from "react";
import Images from "../ImagesComponent/Images";
import data from "../data/header.json";
import NumberFormat from "react-number-format";
import "../css/Header.css";
import $ from "jquery";
import { withRouter } from "react-router";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loadCart: false,
      currentCartFromProps: false,
      isAffiliate: false,
      additionalInfo: null,
      additionalId: null,
      freeCredit: null,
      freeQna: null,
      memberType: "non-member",
      tenants: null,
      email: null,
    };
  }

  deleteKeranjang(args) {
    const { carts } = this.state;
    const postedBody = JSON.stringify({
      transaction_detail_id: args,
      transaction_id: carts[0].transaction_id,
      cartType: "course",
    });

    const url = process.env.REACT_APP_FETCH_URL + "/deletecarts";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: postedBody,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.getCarts();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  hideCart() {
    const { loadCart } = this.state;
    setTimeout(() => {
      this.setState({
        loadCart: false,
      });
    }, 100);
  }

  getCarts() {
    if (
      localStorage.getItem("token") === null ||
      typeof localStorage.getItem("token") === "undefined"
    ) {
      return;
    }
    const memberId = localStorage.getItem("token");
    const url = process.env.REACT_APP_FETCH_URL + "/getcarts/" + memberId;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          let total = 0;
          result.map((res) => {
            total += res.amount * 1;
            res.image =
              res.cartType === "book"
                ? process.env.REACT_APP_BOOKS_IMAGE + res.image
                : process.env.REACT_APP_PRODUCT_IMAGE_URL + res.image;
          });

          this.setState({
            carts: result,
            amount: total,
          });

          if (
            result.length === 0 &&
            !this.props.location.pathname.match("/order-reviews") &&
            !this.props.location.pathname.match("/metode-pembayaran")
          ) {
            localStorage.setItem("96d84", "");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  componentDidUpdate(prevProps) {
    // this.getCarts();
  }

  payment() {
    const { carts } = this.state;
    window.location.href = "/metode-pembayaran/" + carts[0].transaction_id;
  }

  keranjang() {
    window.location.href = "/keranjang";
  }

  checkIsAffiliate() {
    const url =
      process.env.REACT_APP_FETCH_URL +
      "/is_affilaite/" +
      localStorage.getItem("token");
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === true) {
            this.setState({
              isAffiliate: true,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getProfile() {
    if (localStorage.getItem("token") === null) {
      this.logout();
    }

    const token = localStorage.getItem("token");

    const url = localStorage.getItem("a01c12")
      ? "/members/" + token + "/" + localStorage.getItem("a01c12")
      : "/members/" + token;

    fetch(process.env.REACT_APP_FETCH_URL + url)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === null) {
            this.logout();
          }

          this.setState({
            freeCredit:
              result?.memberType === "member" ? 0 : result?.freeCredit,
            freeQna: result?.memberType === "member" ? -1 : result?.freeQna,
            memberType: result?.memberType,
            tenants: result?.tenants,
            activePackages: result?.activePackages,
            email: result?.email,
          });

          if (this.props.setQuotaQna) {
            this.props.setQuotaQna(result.freeQna);
          }

          if (this.props.setMemberType) {
            this.props.setMemberType(this.state.memberType);
          }

          localStorage.setItem("quotaQna", result.freeQna);

          if (result.additionalInfo !== null) {
            this.setState({
              additionalInfo: result.additionalInfo,
              additionalId: result.additionalId,
            });
          }

          this.checkIsAffiliate();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.setState({
        login: true,
        name: localStorage.getItem("name"),
      });

      if (localStorage.getItem("token")) {
        this.getProfile();
      }

      if (localStorage.getItem("token")) {
        this.getCarts();
      }
    }

    setTimeout(() => {
      // window.profileClick();
    }, 1000);

    $(function () {
      setTimeout(function () {
        $("#account").click(function () {
          $("#member-menu").slideToggle();
        });
      }, 500);

      window.addIdOnBody = function (idName) {
        $("body").attr("id", idName);
      };
      $("#close-menu").click(function () {
        $(".inactiveblock-mob").css({ opacity: 0, zIndex: -10, height: 1 });
        $("#checks").prop("checked", false);
        $("#checks").css({ display: "none" });
      });
      $("#burger-menu").click(function () {
        $(".inactiveblock-mob").css({
          opacity: 0.7,
          zIndex: 5,
          height: "100vh",
        });
        $("#checks").prop("checked", true);
        $("#menu-content").css({
          height: "100vh",
        });
      });
      $(".inactiveblock-mob").click(function () {
        $(".inactiveblock-mob").css({ opacity: 0, zIndex: -10, height: 1 });
        $("#checks").prop("checked", false);
      });
      $(".close-menus").click(function () {
        $("#menu-content").css({
          height: 0,
          transition: "height .3s ease-out",
        });
        $(".inactiveblock").css({ opacity: 0, height: 1 });
      });
      $("#profile-user").hover(function () {
        $(".inactiveblock").css({ opacity: 0.7, height: "100vh" });
      });
      $("#kategori-hover").hover(function () {
        $("#menu-content").css({
          display: "block",
          height: 800,
          position: "fixed",
        });
      });
    });
  }

  loadCartValue() {
    this.getCarts();
    setTimeout(() => {
      this.setState({
        loadCart: true,
      });
      window.setScrollBar();
    }, 100);
  }

  logout() {
    localStorage.setItem("token", "");
    localStorage.clear();
    window.location.href = "/";
  }

  render() {
    const {
      login,
      name,
      loadCart,
      carts,
      amount,
      isAffiliate,
      additionalInfo,
      additionalId,
      freeCredit,
      freeQna,
      memberType,
      tenants,
      activePackages,
      email,
    } = this.state;
    const isToken = localStorage.getItem("token");
    return (
      <div id="navigator-utama" className={login ? "loggedin ms-user" : ""}>
        {loadCart && (
          <div id="cart-window" style={{ display: "block" }}>
            <div className="window">
              <b>Keranjang anda</b>
              <a href="#" onClick={() => this.hideCart()}>
                <Images src="images/windowsclose.svg" className="windowclose" />
              </a>
              <div className="content cartwindow scrollbar-macosx">
                {carts &&
                  carts.map((cart) => {
                    return (
                      <div class="kr-item">
                        <div class="item margincontent grey-border">
                          <div style={{ float: "left" }}>
                            <Images
                              src={cart.image}
                              alt=""
                              style={{ width: "149px" }}
                            />
                          </div>
                          <div
                            style={{
                              float: "left",
                              marginLeft: "14px",
                              marginTop: "20px",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Rubik",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              {cart.cartType === "book" && "Harga Buku"}
                              {cart.cartType === "course" && "Harga Kelas"}
                            </span>
                            <p
                              style={{ marginTop: "8px" }}
                              style={{ fontSize: "14px" }}
                            >
                              <NumberFormat
                                value={parseFloat(cart.amount)}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={false}
                                prefix={"Rp "}
                              />
                            </p>
                          </div>
                          <div style={{ float: "right" }}>
                            <a
                              href="#"
                              onClick={(e) =>
                                this.deleteKeranjang(cart.transaction_detail_id)
                              }
                              style={{ float: "right", marginTop: "32px" }}
                            >
                              <Images
                                src="images/trash-delete-icon.svg"
                                class="del-item-but"
                                style={{ width: "18px" }}
                              />
                            </a>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {carts && carts.length > 0 && <hr />}

              {carts && amount > 0 && (
                <b>
                  Total harga kelas{" "}
                  <span>
                    <NumberFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={false}
                      prefix={"Rp "}
                    />
                  </span>
                </b>
              )}

              {carts && carts.length > 0 && (
                <div className="buttons">
                  <button
                    type="button"
                    onClick={() => this.keranjang()}
                    className="fammibuttonalt colorbg4"
                  >
                    Lihat Keranjang
                  </button>
                  <button
                    type="button"
                    onClick={() => this.payment()}
                    className="fammibuttonalt colorbg2"
                  >
                    Lanjut ke pembayaran
                  </button>
                </div>
              )}

              {(!carts || carts.length < 1) && (
                <div style={{ textAlign: "center", height: "265px" }}>
                  <div
                    style={{
                      margin: "24px auto",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      color: "#999",
                      padding: "90px 0",
                    }}
                  >
                    Keranjang Anda Kosong
                  </div>
                  <div style={{ margin: "24px auto", maxWidth: "351px" }}>
                    <a
                      href="/kelas"
                      className="fammibuttonalt teangan-eta-kelas-sina-meuli colorbg2"
                    >
                      Cari Kelas
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="blackshade-cart"></div>
          </div>
        )}
        <div id="menu-header">
          <div id="top-header" style={{ display: "none" }}>
            <div className="container">
              <div className="row">
                <a href="/#">
                  Ingin bekerjasama dengan Fammi menjadi Mitra, Narasumber atau
                  Reseller? Klik disini{" "}
                </a>
                <div className="kontaknav">
                  <a href="/tentang">Tentang Fammi</a>
                  <a href="/join" id="work-btn" className="button-kerja-sama">
                    Kerjasama
                  </a>
                  <a href="/#">CS 089 5351 800 900</a>
                </div>
                <div className="clearing"></div>
              </div>
            </div>
          </div>
          <div className="container menuutama">
            <div className="row">
              <div>
                <a href="/">
                  <Images
                    id="fammi-logo-header"
                    src="images/fammi-logo.svg"
                    alt=""
                    style={{ width: "108px", height: "32px" }}
                  />
                </a>
              </div>
              <div className="menu-kategori">
                <input
                  id="checks"
                  className="burger-checkbox"
                  type="checkbox"
                />
                <div id="burger-menu">
                  <span></span>{" "}
                </div>
                <div id="menu-content" className="menu-content">
                  <div className="container">
                    <div className="row">
                      <div className="menu-content-utama grey-border">
                        <div className="col-sm-12" id="mobile-button-content">
                          <div className="header-closed">
                            <Images
                              src="images/fammi-logo.svg"
                              alt=""
                              style={{ width: "108px", height: "32px" }}
                            />
                            <img
                              src="/assets/images/windowsclose.svg"
                              className="close-menu"
                              id="close-menu"
                            />
                          </div>
                          {login && (
                            <div id="profile-submenu-mob">
                              <div className="subs-menus">
                                <button className="named">
                                  <img
                                    src="/assets/images/profile-pic.svg"
                                    alt="profile-pic"
                                  />
                                  <div>
                                    <p>{name}</p>
                                    <span>{email}</span>
                                  </div>
                                </button>
                                <div className="menu-1">
                                  <button className="btn">
                                    <img
                                      src={`/assets/images/${
                                        memberType !== "member"
                                          ? "premium"
                                          : "premiumed"
                                      }.svg`}
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Fammi Premium</p>
                                      <span>
                                        {tenants &&
                                          "Berlaku Sampai " +
                                            tenants?.expiredDate}
                                        {
                                          !tenants && 
                                          activePackages &&
                                          activePackages.length > 0 &&
                                          "Berlaku Sampai "+ activePackages[0].limitTime
                                        }
                                        {!tenants &&
                                          activePackages &&
                                          activePackages.length === 0 &&
                                          "Belum Aktif"}
                                        {!tenants &&
                                          activePackages &&
                                          activePackages.length === 0 && (
                                            <a href="/daftar-member">
                                              Langganan
                                            </a>
                                          )}
                                      </span>
                                    </div>
                                  </button>

                                  <button className="btn">
                                    <img
                                      src="/assets/images/comments.svg"
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Kuota Bertanya</p>
                                      <span>
                                        {memberType === "member" ? (
                                          "Tanpa Batas"
                                        ) : (
                                          <>Sisa {freeQna} kali bertanya</>
                                        )}
                                      </span>
                                    </div>
                                  </button>

                                  <button className="btn">
                                    <img
                                      src="/assets/images/heads.svg"
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Kuota Audio Materi</p>
                                      <span>
                                        {memberType === "member" ? (
                                          "Tanpa Batas"
                                        ) : (
                                          <>Sisa {freeCredit} audio hari ini</>
                                        )}
                                      </span>
                                    </div>
                                  </button>
                                </div>
                                <div className="menu-2">
                                  {additionalInfo === "speakers" && (
                                    <a href={"/dashboard-speaker/" + additionalId} className="btn">
                                      <img
                                        src="/assets/images/u_notes.svg"
                                        alt="profile-pic"
                                      />
                                      <div>
                                        <p>Laporan Narasumber</p>{" "}
                                      </div>
                                    </a>
                                    )}
                                  <a href="/kelasuser" className="btn">
                                    <img
                                      src="/assets/images/kelas.svg"
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Kelas Saya</p>{" "}
                                    </div>
                                  </a>
                                  {isAffiliate && (
                                    <a href="/affiliate" className="btn">
                                      <img
                                        src="/assets/images/affiliate.svg"
                                        alt="profile-pic"
                                      />
                                      <div>
                                        <p>Affiliate</p>{" "}
                                      </div>
                                    </a>
                                  )}
                                  <a href="/keranjang" className="btn">
                                    <img
                                      src="/assets/images/keranjang.svg"
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Keranjang</p>{" "}
                                    </div>
                                  </a>
                                  <a href="/transaksi" className="btn">
                                    <img
                                      src="/assets/images/transaksi.svg"
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Transaksi</p>{" "}
                                    </div>
                                  </a>
                                  <a href="/profile" className="btn">
                                    <img
                                      src="/assets/images/propil.svg"
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Profile</p>{" "}
                                    </div>
                                  </a>
                                </div>
                                <div className="menu-3">
                                  <a
                                    onClick={() => this.logout()}
                                    href="#"
                                    className="btn"
                                  >
                                    <img
                                      src="/assets/images/keluar.svg"
                                      alt="profile-pic"
                                    />
                                    <div>
                                      <p>Keluar</p>{" "}
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="pad-sides">
                            <div id="mobile-masukdaftar"></div>

                            <div id="section-auth-btn-mob">
                              {!login && (
                                <div className="menu-button" id="menu-masuk">
                                  <a
                                    href="/login"
                                    className="fammibuttonalt colorbg4"
                                    style={{ width: 109 }}
                                  >
                                    Masuk
                                  </a>
                                </div>
                              )}
                              {!login && (
                                <div className="menu-button" id="menu-daftar">
                                  <a
                                    href="/register"
                                    className="fammibuttonalt colorbg2"
                                    style={{ width: 109 }}
                                  >
                                    Daftar
                                  </a>
                                </div>
                              )}
                            </div>
                            {memberType !== "member" && (
                              <div className="menu-button" id="work-btn-mob ">
                                <a
                                  href="/daftar-member"
                                  target="_blank"
                                  className="button-kerja-sama"
                                >
                                  Langganan
                                </a>
                              </div>
                            )}
                            <div id="menu-languange">
                              <div className="row wanlang">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                  <select name="cars" id="language">
                                    <option value="Indonesia">ID</option>
                                    <option value="English">EN</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearing"></div>
                </div>
                <div className="inactiveblock-mob"></div>
              </div>

              <div
                id="menu-search"
                className={`${login ? "loggedin" : " "} ${
                  memberType === "member" ? "member-search " : ""
                }`}
              >
                <div className="col-md-12 search-container">
                  <input
                    id="checks"
                    className={`burger-checkbox-search ${
                      !login ? "right-24" : ""
                    }`}
                    type="checkbox"
                  />
                  <div
                    id="burger-menu-search"
                    className={!login ? "righted-24" : ""}
                  >
                    {" "}
                  </div>
                  <div className="row menu-search-container">
                    <form action="/search">
                      <input
                        type="search"
                        id="fname"
                        name="keyword"
                        placeholder="Kamu mau belajar apa?"
                        autoComplete="off"
                      />
                    </form>
                  </div>

                  <div className="inactiveblock"></div>
                </div>
              </div>
              <div id="header-menu-button">
                {memberType !== "member" && (
                  <div className="menu-button" id="work-btn">
                    <a
                      // className="fammibuttonalt colorbg5 button-kerja-sama"
                      href="/daftar-member"
                      target="_blank"
                      className={`button-kerja-sama ${!login && "notlogin"}`}
                    >
                      Langganan
                    </a>
                  </div>
                )}
                {login && (
                  <div className="menu-button" id="section-credits">
                    <div className="asking">
                      <img src="/assets/images/comment.svg" alt="asking" />
                      <span>{freeQna > -1 ? freeQna : "∞"}</span>
                    </div>

                    <div className="hearing">
                      <img
                        src="/assets/images/headphones.svg"
                        alt="headphones"
                      />
                      <span>{memberType !== "member" ? freeCredit : "∞"}</span>
                    </div>
                  </div>
                )}
                {!login && <div className="separator-notlogin" />}
                {!login && (
                  <div id="section-auth-btn" className="menu-button">
                    <a href="/login" className="fammibuttonalt colorbg4">
                      Masuk
                    </a>
                  </div>
                )}
                {!login && (
                  <div className="menu-button" id="section-auth-btn">
                    <a href="/register" className="fammibuttonalt colorbg2">
                      Daftar
                    </a>
                  </div>
                )}
                {login && (
                  <div className="menu-button" id="profile-user">
                    <div id="prof-parent">
                      <img
                        src="/assets/images/user-pic.svg"
                        alt=""
                        height="34px"
                        width="34px"
                      />
                      <p className="has-sub">My Fammi</p>
                      <img
                        src="/assets/images/profile-down.svg"
                        className="d"
                        alt=""
                        height="6px"
                        width="8px"
                      />
                    </div>
                    <div
                      id={"profile-submenu"}
                      className={`the-sub ${
                        tenants || activePackages ? "sub-profs" : "emp-tent"
                      }`}
                    >
                      <div className="subs-menus">
                        <button className="named">
                          <img
                            src="/assets/images/profile-pic.svg"
                            alt="profile-pic"
                          />
                          <div>
                            <p>{name}</p>
                            <span>{email}</span>
                          </div>
                        </button>
                        <div className="menu-1">
                          <button className="btn">
                            <img
                              src={`/assets/images/${
                                memberType !== "member"
                                  ? "premium"
                                  : "premiumed"
                              }.svg`}
                              alt="profile-pic"
                            />
                            <div>
                              <p>Fammi Premium</p>
                              <span>
                                {tenants &&
                                  "Berlaku Sampai " + tenants?.expiredDate}
                                {
                                          !tenants && 
                                          activePackages &&
                                          activePackages.length > 0 &&
                                          "Berlaku Sampai "+ activePackages[0].limitTime
                                        }
                                {!tenants &&
                                  activePackages &&
                                  activePackages.length === 0 &&
                                  "Belum Aktif"}
                                {!tenants &&
                                  activePackages &&
                                  activePackages.length === 0 && (
                                    <a href="/daftar-member">Langganan</a>
                                  )}
                              </span>
                            </div>
                          </button>
                          {freeQna !== null && (
                            <button className="btn">
                              <img
                                src="/assets/images/comments.svg"
                                alt="profile-pic"
                              />
                              <div>
                                <p>Kuota Bertanya</p>
                                <span>
                                  {memberType === "member" ? (
                                    "Tanpa Batas"
                                  ) : (
                                    <>Sisa {freeQna} kali bertanya</>
                                  )}
                                </span>
                              </div>
                            </button>
                          )}
                          {freeCredit !== null && (
                            <button className="btn">
                              <img
                                src="/assets/images/heads.svg"
                                alt="profile-pic"
                              />
                              <div>
                                <p>Kuota Audio Materi</p>
                                <span>
                                  {memberType === "member" ? (
                                    "Tanpa Batas"
                                  ) : (
                                    <>Sisa {freeCredit} audio hari ini</>
                                  )}
                                </span>
                              </div>
                            </button>
                          )}
                        </div>
                        <div className="menu-2">
                        {additionalInfo === "speakers" && (
                          <a href={"/dashboard-speaker/" + additionalId} className="btn">
                            <img
                              src="/assets/images/u_notes.svg"
                              alt="profile-pic"
                            />
                            <div>
                              <p>Laporan Narasumber</p>{" "}
                            </div>
                          </a>
                        )}
                          <a href="/kelasuser" className="btn">
                            <img
                              src="/assets/images/kelas.svg"
                              alt="profile-pic"
                            />
                            <div>
                              <p>Kelas Saya</p>{" "}
                            </div>
                          </a>
                          {isAffiliate && (
                            <a href="/affiliate" className="btn">
                              <img
                                src="/assets/images/affiliate.svg"
                                alt="profile-pic"
                              />
                              <div>
                                <p>Affiliate</p>{" "}
                              </div>
                            </a>
                          )}
                          <a href="/keranjang" className="btn">
                            <img
                              src="/assets/images/keranjang.svg"
                              alt="profile-pic"
                            />
                            <div>
                              <p>Keranjang</p>{" "}
                            </div>
                          </a>
                          <a href="/transaksi" className="btn">
                            <img
                              src="/assets/images/transaksi.svg"
                              alt="profile-pic"
                            />
                            <div>
                              <p>Transaksi</p>{" "}
                            </div>
                          </a>
                          <a href="/profile" className="btn">
                            <img
                              src="/assets/images/propil.svg"
                              alt="profile-pic"
                            />
                            <div>
                              <p>Profile</p>{" "}
                            </div>
                          </a>
                        </div>
                        <div className="menu-3">
                          <a
                            onClick={() => this.logout()}
                            href="#"
                            className="btn"
                          >
                            <img
                              src="/assets/images/keluar.svg"
                              alt="profile-pic"
                            />
                            <div>
                              <p>Keluar</p>{" "}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="inactiveblock"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="clearing"></div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
